import { getTokenSilently } from '../auth';
import { API_REST_URL } from '../config';

export const createNamespace = async (data: { publicKey: string }) => fetch(`${API_REST_URL}/create-namespace`, {
  method: 'post',
  body: JSON.stringify(data),
  headers: {
    Authorization: `Bearer ${await getTokenSilently()}`,
    'Content-Type': 'application/json',
  },
})
  .then((res) => res.json() as Promise<{ namespace: string }>);
