// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ab2str = (buf: ArrayBuffer) => String.fromCharCode.apply(null, new Uint8Array(buf) as any);

// from https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
const str2ab = (str: string) => {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
};

let keyPair: CryptoKeyPair | undefined;

const generateKeyPair = () => crypto.subtle.generateKey({
  name: 'RSA-PSS',
  modulusLength: 4096,
  publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
  hash: 'SHA-256',
}, true, ['sign', 'verify']);

const exportKey = (publicKey: CryptoKey) => crypto.subtle.exportKey('spki', publicKey).then(ab2str);
const importKey = (publicKey: string) => crypto.subtle.importKey('spki', str2ab(publicKey), {
  name: 'RSA-PSS',
  hash: 'SHA-256',
}, true, ['verify']);

const encodeData = (data: string) => {
  const enc = new TextEncoder();
  return enc.encode(data);
};

const signAlgorithm = {
  name: 'RSA-PSS',
  saltLength: 32,
};

const signData = async (data: string, privateKey: CryptoKey) => {
  const signature = await window.crypto.subtle.sign(signAlgorithm, privateKey, encodeData(data));
  return ab2str(signature);
};

const verifyData = async (data: string, signature: string, publicKey: CryptoKey) => {
  const result = await window.crypto.subtle.verify(
    signAlgorithm,
    publicKey,
    str2ab(signature),
    encodeData(data),
  );
  return result;
};

export const useCrypto = () => {
  const getKeyPair = async () => {
    if (!keyPair) keyPair = await generateKeyPair();
    return keyPair;
  };
  return {
    getPublicKey: () => getKeyPair().then((k) => exportKey(k.publicKey)),
    signData: async (data: string) => signData(
      data,
      await getKeyPair().then((k) => k.privateKey),
    ),
    verifyData: async (data: string, signature: string, publicKey: string) => verifyData(
      data,
      signature,
      await importKey(publicKey),
    ),
  };
};
