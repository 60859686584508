import { nextTick, Ref, watch } from 'vue';

export const truthy = (v: unknown) => !!v;
export const defined = (v: unknown) => v !== null && v !== undefined;

type WaitForValueOptions<T> = {
  done: (v: T) => boolean,
  timeout: number,
}

const defaultOptions = {
  done: truthy,
  timeout: 10000,
};

export const waitForValue = <T, R extends T = T>(
  source: Ref<T>,
  options: Partial<WaitForValueOptions<T>> = {},
) => new Promise<R>(
  (resolve, reject) => {
    const config = {
      ...defaultOptions,
      ...options,
    };
    let done = false;
    const unwatch = watch(source, (val) => {
      if (config.done(val)) {
        resolve(val as R);
        nextTick(unwatch);
        done = true;
      }
    }, { immediate: true });
    if (Number.isFinite(config.timeout)) {
      setTimeout(() => {
        if (!done) {
          unwatch();
          reject(new Error('[waitForValue] timeout'));
        }
      }, config.timeout);
    }
  },
);
