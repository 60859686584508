import {
  defineComponent,
  onBeforeUnmount,
  ref,
  watch,
} from 'vue';
import Peer from 'simple-peer';
import { useChannel } from '@/services/api/privateChannel';
import { SignalEvent } from '@/services/api/types';
import { waitForValue } from '@/services/utils';
import { useCrypto } from '@/services/crypto';

export default defineComponent({
  props: {
    namespace: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    recipientId: {
      type: String,
      required: true,
    },
    recipientPublicKey: {
      type: String,
      default: '',
    },
    initiator: {
      type: Boolean,
      default: false,
    },
    stream: {
      type: MediaStream,
    },
  },
  emits: ['stream', 'ready', 'close', 'data', 'error'],
  setup(props, { emit, slots }) {
    const peer = new Peer({ initiator: props.initiator, stream: props.stream });
    const incomingStream = ref<MediaStream>();
    const connected = ref<boolean>(false);
    const ready = ref<boolean>(false);
    const { connection } = useChannel({ namespace: props.namespace });
    const { signData, verifyData } = useCrypto();
    const { socket, isSocketActive } = connection;
    const handleSignalEvents = async (payload: SignalEvent) => {
      if (payload.to === props.id && payload.from === props.recipientId) {
        const isValidSignature = await verifyData(
          payload.data,
          payload.signature,
          props.recipientPublicKey,
        );
        if (!isValidSignature) {
          console.error('Invalid signature');
        }
        peer.signal(JSON.parse(payload.data));
      }
    };
    const disconnect = () => {
      if (socket.value) {
        socket.value.off('signal', handleSignalEvents);
      }
      peer.destroy();
    };
    let closed = false;
    const onClose = () => {
      ready.value = false;
      connected.value = false;
      closed = true;
      emit('close');
    };
    let isAlive = true;
    peer.on('ping', () => {
      peer.send('pong');
    });
    peer.on('pong', () => {
      isAlive = true;
    });
    const startHeartbeat = () => {
      const timeout = 5000;
      const tick = () => {
        if (!isAlive || !connected.value) {
          if (!closed) onClose();
          return;
        }
        isAlive = false;
        peer.send('ping');
        setTimeout(tick, timeout);
      };
      setTimeout(tick, timeout);
    };
    const connect = async () => {
      if (!socket.value) return;
      socket.value.on('signal', handleSignalEvents);
      peer.on('signal', async (data) => {
        if (!socket.value) return;
        const encodedData = JSON.stringify(data);
        const event: SignalEvent = {
          from: props.id,
          to: props.recipientId,
          data: encodedData,
          signature: await signData(encodedData),
        };
        socket.value.call('publish-signal', event);
      });
      peer.on('connect', () => {
        connected.value = true;
        startHeartbeat();
      });
      peer.on('stream', (stream) => {
        incomingStream.value = stream;
        emit('stream', stream);
      });
      peer.on('data', (_data) => {
        const data = String(_data);
        if (['ping', 'pong'].includes(data)) {
          peer.emit(data);
        } else {
          emit('data', data);
        }
      });
      peer.on('close', onClose);
      peer.on('error', (err) => {
        console.error('--- PEER ERROR ---');
        console.error(err);
        emit('error', err);
      });
      setTimeout(() => {
        ready.value = true;
        emit('ready');
      });
    };
    watch(() => props.stream, (newStream, oldStream) => {
      if (oldStream) peer.removeStream(oldStream);
      if (newStream) peer.addStream(newStream);
    });
    const cleanup = () => {
      disconnect();
      window.removeEventListener('beforeunload', cleanup);
    };
    waitForValue(isSocketActive).then(async () => {
      connect();
      window.addEventListener('beforeunload', cleanup);
    });
    onBeforeUnmount(() => {
      cleanup();
    });

    return () => slots.default?.({
      connected: connected.value,
      ready: ready.value,
      incomingStream: incomingStream.value,
      send: peer.send.bind(peer),
    });
  },
});
