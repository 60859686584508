import {
  computed,
  readonly,
  ref,
  unref,
} from 'vue';
import { v4 as uuid } from 'uuid';
import { createNamespace } from '@/services/api/http';
import { useSocket } from '@/services/api/rpc';
import { useAuth } from '@/services/auth';
import { useCrypto } from '@/services/crypto';
import { defined, waitForValue } from '../utils';

export const useChannel = (options: { namespace?: string } = {}) => {
  const id = uuid();
  const isOpen = ref(false);
  const namespace = ref<string | undefined>(options.namespace || undefined);
  const { user, isAuthenticated, getTokenSilently } = useAuth();
  const { getPublicKey } = useCrypto();
  const publicKey = ref<string | undefined>();

  const wsConnection = ref<ReturnType<typeof useSocket>>();

  (async () => {
    publicKey.value = await getPublicKey();
    await waitForValue(isAuthenticated, { done: defined });
    if (!options.namespace) {
      if (!user.value) throw Error('Unauthorized');

      const response = await createNamespace({ publicKey: publicKey.value });
      namespace.value = response.namespace;
    }

    const ws = useSocket(namespace.value);
    wsConnection.value = ws;
    await ws.connect();
    if (isAuthenticated.value && ws.socket.value) {
      await ws.socket.value.login({ accessToken: await getTokenSilently() });
    }
    isOpen.value = true;
  })();

  return {
    id,
    isOpen: readonly(namespace),
    namespace: readonly(namespace),
    publicKey: readonly(publicKey),
    connection: {
      socket: computed(() => unref(wsConnection.value?.socket)),
      isSocketActive: computed(() => unref(wsConnection.value?.isSocketActive)),
    },
  };
};
