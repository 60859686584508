import { Client as WebSocketClient } from 'rpc-websockets';
import { computed, Ref, ref } from 'vue';
import { API_WS_URL } from '../config';
import { waitForValue } from '../utils';

const webSocketClients: Map<string, { socket: WebSocketClient, active: Ref<boolean> }> = new Map();

const createWebSocketClient = (namespace = '') => {
  const client = {
    socket: new WebSocketClient(`${API_WS_URL}/${namespace}`),
    active: ref(false),
  };
  webSocketClients.set(namespace, client);
  client.socket.on('open', () => {
    client.active.value = true;
  });
  client.socket.on('close', () => {
    client.active.value = false;
  });
  client.socket.on('error', (error) => {
    console.error(error);
    client.active.value = false;
  });
  return client;
};

export const useSocket = (namespace = '') => {
  const connect = async () => {
    if (!webSocketClients.get(namespace)) {
      const client = createWebSocketClient(namespace);
      await waitForValue(client.active);
    }
  };
  return {
    connect,
    socket: computed(() => webSocketClients.get(namespace)?.socket),
    isSocketActive: computed(() => webSocketClients.get(namespace)?.active),
  };
};
