import { shallowRef } from 'vue';
import * as Tone from 'tone';

export const NOISE_TYPES = {
  white: 'biały',
  brown: 'brązowy',
  pink: 'różowy',
} as const;

export type NoiseType = Tone.NoiseType;

export type NoiseStatusMessage = {
  active: boolean,
  volume: number,
  type: NoiseType,
}

export const useNoise = () => {
  const noise = shallowRef(new Tone.Noise('white').toDestination());

  noise.value.fadeIn = 2;
  noise.value.fadeOut = 2;

  const startNoise = () => {
    noise.value.start();
  };

  const setVolume = (value: number) => {
    noise.value.volume.value = value;
  };

  const setType = (value: NoiseType) => {
    noise.value.type = value;
  };

  const stopNoise = () => {
    noise.value.stop();
  };

  return {
    noise,
    startNoise,
    stopNoise,
    setVolume,
    setType,
  };
};
